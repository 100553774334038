import React from 'react';

const TableLegend = () => {
  return (
    <div className="flex items-center justify-end mt-6">
      <div className="flex items-center">
        <div className="bg-racqy-red w-6 h-6 outline-none rounded-md mr-1" />
        <h6 className="font-sans">Busy</h6>
      </div>
      <div className="flex items-center sm:ml-8 ml-2">
        <div className="w-6 h-6 border-[1px] border-gray-200 rounded-md mr-1" />
        <h6 className="font-sans">Available</h6>
      </div>
      <div className="flex items-center sm:ml-8 ml-2">
        <div className="bg-racqy-blue w-6 h-6 outline-none rounded-md mr-1" />
        <h6 className="font-sans">Your booking</h6>
      </div>
    </div>
  );
};

export default TableLegend;
