import React from 'react';

const ClubProfileBannerButton = ({
  title,
  className,
  onClick,
  icon,
  isFavourite,
}) => {
  return (
    <button
      className={`bg-white flex items-center justify-center px-4 py-2 rounded-full text-sm ${className}`}
      onClick={onClick}
    >
      <img
        src={icon}
        className={`w-4 h-4 mr-2 ${!isFavourite && 'sport-icon-img'}`}
      />
      {title}
    </button>
  );
};

export default ClubProfileBannerButton;
