import React, { useEffect, useState } from 'react';
import './index.css';
import TableLegend from './TableLegend';
import { tableTime } from '../../utils/constants';
import moment from 'moment';
import { useAuthContext } from '../../contexts/auth';

const ClubProfileTable = ({ club, sport, bookings }) => {
  const { user } = useAuthContext();

  let currentTime = moment().format('HH');

  const [bookingTableData, setBookingTableData] = useState({});

  const calculateDiff = (time1, time2) => {
    let startTimeMinutes = parseInt(time1.hour * 60) + parseInt(time1.min);
    let endMinutes = parseInt(time2.hour * 60) + parseInt(time2.min);

    var numArray = [startTimeMinutes, endMinutes].sort((a, b) => {
      return a - b;
    });

    let diff = numArray[1] - numArray[0];
    return diff;
  };

  const calculateWidth = (difference) => {
    let timeDiff = String(difference);

    if (timeDiff === '0.5' || timeDiff === '0.25') {
      return 36;
    }
    if (String(difference).includes('.') && difference !== '0.5') {
      let differenceSplit = timeDiff.split('.');

      return 36 * Number(differenceSplit[0]);
    } else {
      return 36 * Number(timeDiff);
    }
  };

  useEffect(() => {
    if (bookings.length > 0) {
      let temp = {};

      const sportBookings = club?.products.filter(
        (item) => item?.sports?.label === sport
      );
      for (let i = 0; i < tableTime.length; i++) {
        for (let j = 0; j < bookings.length; j++) {
          let isProductExist = sportBookings.find(
            (item) => item?.id === bookings[j]?.court
          );
          if (!isProductExist) {
            return;
          }

          let startTimeSplit = bookings[j]?.startTime.split(':');
          let endTimeSplit = bookings[j]?.endTime.split(':');

          let formattedStartTime = `0${startTimeSplit[0]}`.slice(-2);

          if (formattedStartTime === tableTime[i]) {
            let startAndEndTimeDifference = calculateDiff(
              { hour: formattedStartTime, min: startTimeSplit[1] },
              { hour: endTimeSplit[0], min: endTimeSplit[1] }
            );

            let startEndTimeHrsDiff = startAndEndTimeDifference / 60;

            temp[`${bookings[j]?.court}-${formattedStartTime}`] = {
              ...bookings[j],
              key: `${bookings[j]?.court}-${formattedStartTime}`,
              startTimeHour: formattedStartTime,
              width: calculateWidth(startEndTimeHrsDiff),
            };
          } else if (
            tableTime[i] === formattedStartTime &&
            startTimeSplit[1] !== '00'
          ) {
            let startAndEndTimeDifference = calculateDiff(
              { hour: formattedStartTime, min: startTimeSplit[1] },
              { hour: endTimeSplit[0], min: endTimeSplit[1] }
            );

            let startEndTimeHrsDiff = startAndEndTimeDifference / 60;

            temp[`${bookings[j]?.court}-${formattedStartTime}`] = {
              ...bookings[j],
              key: `${bookings[j]?.court}-${formattedStartTime}`,
              startTimeHour: formattedStartTime,
              width: calculateWidth(startEndTimeHrsDiff),
            };
          }
        }
      }
      setBookingTableData(temp);
    } else {
      setBookingTableData({});
    }
  }, [bookings]);

  return (
    <>
      <div className="rounded-lg border-[1px] w-full mt-5 pb-10 overflow-x-scroll">
        <table className="w-full">
          <thead>
            <tr>
              <th
                colSpan={tableTime.length + 1}
                className="py-3 px-6 w-full text-left rounded-t-lg"
              >
                <p
                  style={{ fontSize: 20, fontWeight: 'bold' }}
                >{`Book ${sport}`}</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="w-32 min-w-[6rem] h-9 min-h-[2.25rem] border-[1px] border-l-0"></td>
              {tableTime.map((item, index) => (
                <td
                  key={index}
                  className={`
                  ${
                    index === 0 && 'border-t-[1px] border-b-[1px] border-l-0'
                  } border-t-[1px] border-b-[1px] border-l-[1px] w-9 h-9 min-w-[2.25rem] min-h-[2.25rem] text-center text-xs font-bold`}
                >
                  {item}
                </td>
              ))}
            </tr>
            {club?.products.map((item, index) => (
              <tr key={index} className="relative">
                <td
                  className={`
                  ${
                    index === 0 && 'border-t-0 border-b-[1px]'
                  }  border-t-[1px] border-b-[1px] min-w-[2.25rem] min-h-[2.25rem] w-9 h-9 text-center text-sm font-bold`}
                >
                  {item.courtName}
                </td>
                {tableTime.map((timeItem, columnIndex) => {
                  let bookingKey = bookingTableData[item?.id + '-' + timeItem];

                  return (
                    <td
                      key={columnIndex}
                      className={`
                    ${columnIndex === 0 && 'border-t-[1px] border-b-[1px]'} ${
                        timeItem === currentTime && 'active'
                      } ${
                        index === 0 && timeItem === currentTime && 'active-dot'
                      }
                    border-t-[1px] border-b-[1px] border-l-[1px] w-9 h-9 min-w-[2.25rem] min-h-[2.25rem] text-center relative`}
                    >
                      {bookingKey?.startTimeHour === timeItem && (
                        <div
                          style={{
                            position: 'absolute',
                            width: `${bookingKey?.width}px`,
                            minWidth: `${bookingKey?.width}px`,
                            height: '36px',
                            borderRadius: '6px',
                            backgroundColor:
                              bookingKey?.userId === user?.uid
                                ? '#209CA2'
                                : '#FD6E84',
                            top: 0,
                          }}
                        ></div>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TableLegend />
    </>
  );
};

export default ClubProfileTable;
