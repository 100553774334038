import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import background from '../images/sports-background.jpeg';
import clubProfileImg from '../images/club_profile.svg';
import locationPin from '../../static/icons/location-pin.svg';
import ClubProfileBannerButton from '../components/ClubProfileBannerButton';
import heart from '../../static/icons/heart.svg';
import favHeart from '../../static/icons/red-heart-icon.svg';
import map from '../images/icons/map.svg';
import ClubProfileSideBar from '../components/ClubProfileSideBar';
import DatePicker from '../components/ClubProfileDatePicker';
import ClubProfileTable from '../components/ClubProfileTable';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { managerClient } from '../api/manager-client';
import { booking, clubs } from '../api/api-requests';
import { isBrowser } from '../utils/isBrowser';
import { useAuthContext } from '../contexts/auth';
import { changeIsFavourite, getClubInfo } from '../redux/slices/bookingSlice';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import Login from '../components/Login';
import Modal from '../components/Modal';

const ClubProfile = ({ params, location }) => {
  const clubID = params['*'];

  const { user } = useAuthContext();

  const dispatch = useDispatch();
  const { modalOpen, setModalOpen } = useFirebaseAuth();

  const [date, setDate] = useState(new Date());
  const [sport, setSport] = useState('All Sports');
  const [loading, setLoading] = useState(true);
  const [supportedSports, setSupportedSports] = useState([]);
  const [availableSports, setAvailableSports] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);

  const selectedDate = useSelector(
    (state) => state.bookingReducer.selectedDate
  );
  const club = useSelector((state) => state.bookingReducer.clubProfileData);

  useEffect(() => {
    if (club) {
      const sports = club?.products.map((item) => item?.sports?.label);
      setSupportedSports(['All Sports', ...sports]);
      setAvailableSports([...sports]);

      fetchBookings(club?.clubId, selectedDate);

      setTimeout(() => setLoading(false), 1500);
    }
  }, [club, selectedDate]);

  useEffect(() => {
    if (user) {
      dispatch(getClubInfo({ clubId: clubID, userId: user?.uid }));
    } else {
      dispatch(getClubInfo({ clubId: clubID }));
    }
  }, [user]);

  const fetchBookings = async (clubId, selectedDate) => {
    try {
      const res = await managerClient(
        booking.getBooking({
          clubId,
          queryParams: selectedDate,
        })
      );

      if (res?.data?.success) {
        setBookings([...res?.data?.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const giveDirections = () => {
    if (club) {
      let query = `${club?.clubGeoLoc?.lat},${club?.clubGeoLoc?.lng}`;
      if (club?.clubInfo?.address) {
        query = club?.clubInfo?.address.replace(/[\r\n]/gm, ' ');
      }

      const destination = encodeURIComponent(`${query}`);
      const link = `http://maps.google.com/?daddr=${destination}`;

      if (isBrowser()) {
        window.open(link);
      }
    }
  };

  useEffect(() => {
    if (sport !== 'All Sports') {
      const sportBookings = club?.products.filter(
        (item) => item?.sports?.label === sport
      );

      let temp = [];

      bookings.map((item) => {
        let bookingFilter = sportBookings.find(
          (product) => product?.id === item?.court
        );

        if (bookingFilter) {
          temp.push(item);
        }
      });

      setFilteredBookings([...temp]);
    }
  }, [sport, bookings]);

  const handleFavClub = async () => {
    try {
      await managerClient(
        clubs.favClub({
          clubId: club?.clubId,
          key: club?.isFavourite ? 'unfavourite' : 'favourite',
          userId: user?.uid,
        })
      );

      dispatch(changeIsFavourite(!club?.isFavourite));
    } catch (error) {
      console.log(error);
    }
  };

  function openModal() {
    setModalOpen(true);
  }

  function hideModal() {
    setModalOpen(false);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Layout location={location}>
      <Helmet>
        <title>Racqy</title>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css"
          integrity="sha256-t2ATOGCtAIZNnzER679jwcFcKYfLlw01gli6F6oszk8="
          crossorigin="anonymous"
        />
      </Helmet>
      <div className="w-full h-full">
        {/* Top Section with image or in future map will be shown */}
        <div className="h-72 w-full relative">
          <img src={background} className="h-full w-full object-cover" />
          <div className="absolute flex items-center lg:top-[220px] lg:right-[10%] top-[150px] right-[5%]">
            <ClubProfileBannerButton
              title="Directions"
              icon={map}
              onClick={giveDirections}
            />
            {user ? (
              <ClubProfileBannerButton
                title={club?.isFavourite ? 'Liked' : 'Add to favourites'}
                className="ml-3"
                icon={club?.isFavourite ? favHeart : heart}
                onClick={handleFavClub}
                isFavourite={club?.isFavourite}
              />
            ) : (
              <ClubProfileBannerButton
                title="Add to favourites"
                className="ml-3"
                icon={heart}
                onClick={openModal}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center relative mb-14">
          <div className="bg-white h-28 w-28 flex items-center justify-center rounded-t-2xl absolute">
            <img
              src={
                club?.clubInfo?.clubImage
                  ? club?.clubInfo?.clubImage
                  : clubProfileImg
              }
              className="w-24 h-24"
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h5 className="font-medium font-sans leading-none">
            {club?.clubInfo?.clubName}
          </h5>
          <div className="flex items-center justify-center">
            <img src={locationPin} className="w-5" />
            <h6 className="text-xs font-sans text-gray-400">
              {club?.clubInfo?.clubLocations?.label}
            </h6>
          </div>
        </div>
        {/* Below section containing tables sidebar */}
        <div className="w-full lg:flex lg:justify-between px-[50px] md:pl-[150px] md:pr-[160px] mb-20">
          {/* Tables section */}
          <div className="lg:w-[80%] w-full lg:pr-[10%] pr-0">
            {/* Datepicker and sport select section */}
            <div className="mt-10 max-sm:flex max-sm:flex-col sm:max-lg:flex sm:max-lg:justify-between sm:max-lg:items-center">
              <select
                className="max-sm:w-full w-[200px] px-[20px] py-2.5 focus:outline-none focus:ring-0 border-[1px] border-gray-200 focus:border-gray-200 rounded-[5px] max-sm:mb-3"
                name="sport"
                id="sports"
                placeholder="Sport"
                value={sport}
                onChange={(evt) => setSport(evt.target.value)}
              >
                {supportedSports.map((sport, index) => (
                  <option value={sport} key={`sport-${index}`}>
                    {sport}
                  </option>
                ))}
              </select>
              <DatePicker date={date} setDate={setDate} />
            </div>
            {/* Tables section */}
            {sport === 'All Sports' ? (
              availableSports.map((sport) => (
                <ClubProfileTable
                  club={club}
                  sport={sport}
                  bookings={user ? bookings : []}
                />
              ))
            ) : (
              <ClubProfileTable
                club={club}
                sport={sport}
                bookings={user ? filteredBookings : []}
              />
            )}
          </div>
          {/* Sidebar section */}
          <ClubProfileSideBar club={club} />
        </div>
      </div>
      {modalOpen && (
        <Modal
          customStyle={{
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onRequestClose={hideModal}
        >
          <Login />
        </Modal>
      )}
    </Layout>
  );
};

export default ClubProfile;
