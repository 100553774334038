import React from 'react';
import './index.css';
import { getSportIcon } from '../../helper/racqyHelper';

const ClubProfileSideBar = ({ club }) => {
  const DAYS = [
    {
      day: 'Monday',
      time: `${club?.clubInfo?.openingHours?.monday?.start}-${club?.clubInfo?.openingHours?.monday?.end}`,
      isChecked: club?.clubInfo?.openingHours?.monday?.isChecked,
    },
    {
      day: 'Tuesday',
      time: `${club?.clubInfo?.openingHours?.tuesday?.start}-${club?.clubInfo?.openingHours?.tuesday?.end}`,
      isChecked: club?.clubInfo?.openingHours?.tuesday?.isChecked,
    },
    {
      day: 'Wednesday',
      time: `${club?.clubInfo?.openingHours?.wednesday?.start}-${club?.clubInfo?.openingHours?.wednesday?.end}`,
      isChecked: club?.clubInfo?.openingHours?.wednesday?.isChecked,
    },
    {
      day: 'Thursday',
      time: `${club?.clubInfo?.openingHours?.thursday?.start}-${club?.clubInfo?.openingHours?.thursday?.end}`,
      isChecked: club?.clubInfo?.openingHours?.thursday?.isChecked,
    },
    {
      day: 'Friday',
      time: `${club?.clubInfo?.openingHours?.friday?.start}-${club?.clubInfo?.openingHours?.friday?.end}`,
      isChecked: club?.clubInfo?.openingHours?.friday?.isChecked,
    },
    {
      day: 'Saturday',
      time: `${club?.clubInfo?.openingHours?.saturday?.start}-${club?.clubInfo?.openingHours?.saturday?.end}`,
      isChecked: club?.clubInfo?.openingHours?.saturday?.isChecked,
    },
    {
      day: 'Sunday',
      time: `${club?.clubInfo?.openingHours?.sunday?.start}-${club?.clubInfo?.openingHours?.sunday?.end}`,
      isChecked: club?.clubInfo?.openingHours?.sunday?.isChecked,
    },
    {
      day: '*Holidays',
      time: `${club?.clubInfo?.openingHours?.holidays?.start}-${club?.clubInfo?.openingHours?.holidays?.end}`,
      isChecked: club?.clubInfo?.openingHours?.holidays?.isChecked,
    },
  ];

  const CONTACT = [
    {
      label: 'Phone Number',
      value: `${club?.clubInfo?.clubPhoneCode?.callingCode} ${club?.clubInfo?.phoneNumber}`,
    },
    {
      label: 'Email',
      value: club?.clubInfo?.clubEmail,
    },
    {
      label: 'Website',
      value: club?.clubInfo?.clubWebsite,
    },
  ];

  return (
    <div className="lg:w-[20%] w-full flex lg:flex-col lg:items-center lg:justify-between sm:max-lg:flex-wrap sm:max-lg:justify-between max-sm:flex-wrap max-sm:mt-10 sm:max-lg:mt-7">
      {/* Available sports section */}
      <div className="w-72 rounded-xl shadow-gray-100 shadow-lg px-5 py-3 flex-grow sm:max-lg:mr-2">
        <h6 className="font-sans">Available sports</h6>
        <div
          className={`flex flex-wrap items-center ${
            club?.products.length > 0 ? 'mt-7' : 'mt-3'
          }`}
        >
          {club?.products.length > 0 ? (
            club?.products.map((item, index) => (
              <div
                className={`flex items-center h-9 bg-gray-100 border-gray-200 border-[1px] rounded-full w-fit px-3 mb-3 ${
                  index % 2 !== 0 && 'lg:ml-3'
                } ${index > 0 && 'max-lg:ml-1'}`}
              >
                <img
                  src={getSportIcon(item?.sports?.value)}
                  className="sport-icon-img w-5 h-5 mr-1"
                />
                <h6>{item?.sports?.label}</h6>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center w-full">
              <h6 className="font-sans">No sport available yet</h6>
            </div>
          )}
        </div>
      </div>
      {/* Club opening hours section */}
      <div className="w-72 max-sm:mt-6 lg:mt-6 rounded-xl shadow-gray-100 shadow-lg px-5 py-3 pt-6">
        <h6 className="font-sans">Club opening hours</h6>

        <div className="mt-7">
          {DAYS.filter((data) => data.isChecked).map((day, index) => (
            <div
              className={`flex items-center justify-between py-1.5 ${
                index !== DAYS.filter((data) => data.isChecked).length - 1 &&
                'border-b-[1px] border-gray-200'
              }`}
            >
              <h6 className="font-sans">{day.day}</h6>
              <h6 className="font-sans font-normal text-xs">{day.time}</h6>
            </div>
          ))}
        </div>
      </div>
      {/* Contact section */}
      <div className="w-72  mt-6 rounded-xl shadow-gray-100 shadow-lg px-5 py-3 pt-6">
        <h6 className="font-sans">Contact</h6>

        <div className="mt-7">
          {CONTACT.map((item, index) => (
            <div
              className={`flex items-center justify-between py-1.5 ${
                index !== CONTACT.length - 1 && 'border-b-[1px] border-gray-200'
              }`}
            >
              <h6 className="font-sans">{item.label}</h6>
              <h6 className="font-sans font-normal text-xs">{item.value}</h6>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClubProfileSideBar;
