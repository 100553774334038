import React from 'react';
import './index.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/icons/date.svg';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { changeSelectedDate } from '../../redux/slices/bookingSlice';

export default function ClubProfileDatePicker({ date, setDate }) {
  const dispatch = useDispatch();

  const CustomInput = ({ value, onClick }) => (
    <div className="date-containerr" onClick={onClick}>
      <input
        className="w-full inputt focus:outline-none focus:ring-0 border-[1px] border-gray-200 focus:border-gray-200"
        type="text"
        value={value}
        readOnly
      />
      <img src={calendarIcon} className="calendarr-icon" />
    </div>
  );

  const handleChange = (date) => {
    setDate(date);
    dispatch(changeSelectedDate(moment(date).format('YYYY-MM-DD')));
  };

  return (
    <DatePicker
      selected={date}
      value={moment(date).format('DD/MM')}
      onChange={(date) => handleChange(date)}
      customInput={<CustomInput />}
    />
  );
}
